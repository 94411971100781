<template>
  <v-container
    id="predicao-historico"
    fluid
    tag="section"
  >

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-text >
          <p> Quer mesmo deletar a ordem {{orderToDelete}}? </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDeleteOrder()"
          >
            Confirmar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false; orderToDelete = ''"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <add-fixed-button @clicked="addOrder()" />

    <v-card-text style="height: 100px; position: relative">

    </v-card-text>

    <v-row>
      <v-col 
        cols="12"
      >
        <base-material-card
          color="indigo"
          icon="mdi-clipboard-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Ordens
            </div>
          </template>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto"
            label="Pesquisar"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider class="mt-3" />

          <v-data-table
            :headers="headers"
            :items="items"
            :search.sync="search"
            :sort-desc="[false, true]"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            multi-sort
          >
            <template v-slot:item="row">
              <tr>

                <td class="caption">{{row.item.arrival}}</td>
                <td width="10px" class="caption">{{row.item.id}}</td>
                <td class="button">{{row.item.client}}</td>
                <td width="150px" class="button">{{row.item.cpf}}</td>
                <td width="200px" class="caption">{{row.item.productmodel}}</td>
                <td width="70px" class="caption">{{row.item.serial_number}}</td>
                <td width="30px" class="caption">{{row.item.status}}</td>
                <!--td class="button">{{row.item.approval == true ? "Aprovada" : row.item.approval == false ? "Negada" : ""}}</td-->
                <td class="caption">{{row.item.modified_by_in}}</td>
                <td width="5px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="blue-grey lighten-3"
                        class="px-2 ml-1"
                        min-width="0"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small> mdi-menu-down-outline </v-icon>
                      </v-btn>
                    </template>
                    <v-list>

                      <v-list-item @click="editOrder(row.item.id)">
                        <v-list-item-title>
                          <v-icon>mdi-pencil</v-icon>
                          Editar Ordem
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="generateEntryOrder(row.item.id)">
                        <v-list-item-title>
                          <v-icon>mdi-file-document-multiple-outline</v-icon>
                          Ordem Entrada
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="generateOrder(row.item.id)">
                        <v-list-item-title>
                          <v-icon>mdi-file-document-multiple-outline</v-icon>
                          Ordem Saída
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="deleteOrder(row.item.id)">
                        <v-list-item-title>
                          <v-icon>mdi-trash-can-outline</v-icon>
                          Deletar Ordem
                        </v-list-item-title>
                      </v-list-item>
                      
                    </v-list>
                  </v-menu>

                  <!--v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="info"
                    v-clipboard="row.item.code"
                    @click="copyCode(row.item.code)"
                  >
                    <v-icon>mdi-key</v-icon>
                  </v-btn-->                  
                </td>

              </tr>
            </template>

          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import OrdersService from '@/services/OrdersService'
  import dayjs from 'dayjs'

  export default {
    name: 'OrdersIndex',

    components: {
      AddFixedButton: () => import('@/components/base/AddFixedButton'),
    },

    data () {
      return {
        dialog: false,
        orderToDelete:"",
        search: '',
        items: [],
        headers: [
        {
          text: 'Data Chegada',
          value: 'arrival',
        },
        {
          text: 'Nº',
          value: 'id',
        },
        {
          text: 'Cliente',
          value: 'client',
        },
        {
          text: 'CPF/CNPJ',
          value: 'cpf',
        },
        {
          text: 'Equipamento',
          value: 'productmodel',
        },
        {
          text: 'Nº de Série',
          value: 'serial_number',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Modificado',
          value: 'modified_by_in',
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
        },
      ],
      }
    },

    mounted () {
      this.getAllOrders()
    },

    computed: {
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    },

    methods: {

      copyCode(code) {
        this.$toast.success('Código Copiado', '',{position:'topRight'})
      },

      addOrder () {
        this.$router.push({name: 'Criar Ordem'})
      },

      editOrder (id) {
        this.$router.push('/orders/edit/'+id)
      },

      generateOrder (id) {
        window.open(process.env.VUE_APP_API_BASE_URL+"/orders/serviceorder/"+id, '_blank')
      },

      generateEntryOrder (id) {
        window.open(process.env.VUE_APP_API_BASE_URL+"/orders/entryorder/"+id, '_blank')
      },

      confirmDeleteOrder(){
        const id = this.orderToDelete
        OrdersService.deleteOrder(id)
        .then(response => {
          if(response.data.message == "Error"){
            throw 500
          }
          this.items = this.items.filter(element => element.id != id)
          this.$toast.success('Ordem deletado.', '',{position:'topRight'})
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao deletar.', '',{position:'topRight'}) 
        })
        this.dialog = false
      },

      deleteOrder (id) {
        this.dialog = true
        this.orderToDelete = id
      },

      getAllOrders (page) {
        OrdersService.getOrders()
        .then(response => {
          this.items = []
          let obj = {}
          response.data.orders.forEach(element => {
            obj = {
              id: element.id,
              //title: element.title,
              cpf: element.client.cpf,
              productmodel: element.productmodel,
              serial_number: element.serial_number,
              status: element.status,
              //code: element.code,
              //approval: element.approval,
              client: element.client.name,
              //modified: dayjs(element.modified).format('DD/MM/YY HH:mm:ss'),
              arrival: dayjs(element.arrival_date).format('DD/MM/YY')+' '+element.arrival_time,
              //modified_by: element.user.name,
              modified_by_in: element.user.name+' '+dayjs(element.modified).format('DD/MM/YY HH:mm:ss'),
            }
            this.items.push(obj);
          });
        })
        .catch(error => console.log(error))
      },
    },

  }
</script>
